import * as React from "react";
import { Link } from "gatsby"

const LineCallout = ({
  className,
  linkClassName,
  linkText,
  linkUlr,
  children,
  ...props
}) => {
  return (
    <div {...props} className={`line-callout ${className ? className : ""}`}>
      <div className="line-cta-wrapper">
        <Link
          className={`callout-title ${linkClassName ? linkClassName : ""}`}
          dangerouslySetInnerHTML={{ __html: linkText }}
          to={linkUlr}
        />
        { children }
        </div>
    </div>
  )
}

export default LineCallout
