import React from "react";
import { Container, Col, Row } from 'react-grid-system';
import { Link } from "gatsby";

function PageCallout({
  imgIcon,
	linkUrl,
	linkText,
	className,
  ctaTitle,
  ctaCopy,
	children,
	...props
}) {
  return (
    <div {...props} className={`page-cta ${className ? className : ''}`}>
			<Container fluid>
				<Row>
					<Col xs={2} md={1}>
						<div className='cta-icon-wrapper'>
							<Link to={linkUrl}>
								<img src={imgIcon} alt='' />
							</Link>
						</div>
					</Col>
					<Col xs={10} md={11}>
						<div className='cta-text-wrapper'>
              <h4 className="cta-title" dangerouslySetInnerHTML={{ __html: ctaTitle }}></h4>
              <p dangerouslySetInnerHTML={{ __html: ctaCopy }}></p>
						</div>
            <div className='cta-link-wrapper'>
              <Link
								to={linkUrl}
								dangerouslySetInnerHTML={{ __html: linkText }}
                className="cta-link"
							/>
            </div>
					</Col>
				</Row>
			</Container>
		</div>
  );
}

export default PageCallout;